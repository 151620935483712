import { useAuth0, type LogoutOptions } from '@auth0/auth0-react';
import { useEffect, useRef } from 'react';
import { Eye, Logout, Leaf, spacing } from 'component-library';
import { useNavigate } from 'react-router-dom';
import UserProfileLink from './UserProfileLink';
import {
  BottomPart,
  DropDownMenuInnerWrapper,
  DropDownMenuWrapper,
  Email,
  Link,
  LinkIconWrapper,
  LinkWrapper,
  LogoutButton,
  LogoutIconWrapper,
  Name,
  UserInfo,
} from './styles';
import ContactSupportLink from './ContactSupportLink';
import type { TextType } from '../HeaderMenu';
import type { Profile } from '../Profile/Profile';

type DropDownMenuProps = {
  profile: Profile | null;
  logout: (options?: LogoutOptions) => void;
  closeMenu: () => void;
  text: TextType;
};

export const DropDownMenu = ({ profile, logout, closeMenu, text, ...rest }: DropDownMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { getAccessTokenSilently } = useAuth0();

  const isSuperUser = !!profile?.permissions?.superUser;

  useEffect(() => {
    const handleOutsideClick = (e: Event) => {
      if (!ref.current?.contains(e.target as Node)) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleOutsideClick, false);

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [closeMenu]);

  const navigate = useNavigate();

  const isDev = process.env.NODE_ENV === 'development';

  return (
    <DropDownMenuWrapper ref={ref} {...rest}>
      <DropDownMenuInnerWrapper>
        <UserInfo>
          <Name>{profile?.fullName}</Name>
          <Email>{profile?.email}</Email>
        </UserInfo>
        <BottomPart>
          <UserProfileLink text={text} closeMenu={closeMenu} />
          <ContactSupportLink text={text} closeMenu={closeMenu} />
          {isDev && (
            <LinkWrapper>
              <Link
                href="#"
                onClick={async (e) => {
                  e.preventDefault();
                  await getAccessTokenSilently({ cacheMode: 'off' });
                  window.location.reload();
                }}
              >
                <LinkIconWrapper>
                  <Eye width={spacing.small} height={spacing.small} />
                </LinkIconWrapper>
                Renew token (dev)
              </Link>
            </LinkWrapper>
          )}
          {isSuperUser && (
            <LinkWrapper>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  closeMenu();
                  navigate('/health');
                }}
              >
                <LinkIconWrapper>
                  <Leaf width={spacing.small} height={spacing.small} />
                </LinkIconWrapper>
                Health
              </Link>
            </LinkWrapper>
          )}
          <LogoutButton
            data-testid="logout-button"
            onClick={() => {
              logout();
            }}
          >
            <LogoutIconWrapper>
              <Logout width={spacing.small} height={spacing.small} />
            </LogoutIconWrapper>
            {text.signOut}
          </LogoutButton>
        </BottomPart>
      </DropDownMenuInnerWrapper>
    </DropDownMenuWrapper>
  );
};
