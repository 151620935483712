import { useContext } from 'react';
import { ServiceContext } from './ServiceProvider';

export type MenuService = {
  id: string;
  name: string;
  description: string;
  link: string;
  linkText: string;
  showInTopMenu: boolean;
  availableInCountries: string;
};

export const useServices = () => {
  const context = useContext(ServiceContext);

  if (!context) {
    throw Error('Wrap your app with `ServiceProvider`');
  } else {
    return { services: context.services, loading: context.loading };
  }
};
