export const ONE_SPA_ROUTE_EMISSIONS_BASE_PATH = '/co2impact/emissions';
export const ONE_SPA_ROUTE_EMISSIONS_PARAMS = ':view/:reportType';
export const ONE_SPA_ROUTE_ABOUT_BASE_PATH = '/co2impact/about';
export const ONE_SPA_ROUTE_ABOUT_GET_STARTED_PATH_NAME = 'get-started';
export const ONE_SPA_ROUTE_ABOUT_METHOD_PATH_NAME = 'our-method';
export const ONE_SPA_ROUTE_ABOUT_FOOTPRINTS_PATH_NAME = 'our-footprints';
export const ONE_SPA_ROUTE_ABOUT_BENEFITS_PATH_NAME = 'key-benefits';
export const ONE_SPA_ROUTE_ABOUT_STANDARDS_PATH_NAME = 'climate-standards';
export const ONE_SPA_ROUTE_PRICING_BASE_PATH = '/co2impact/pricing';
export const ONE_SPA_ROUTE_ABOUT_GET_STARTED = `${ONE_SPA_ROUTE_ABOUT_BASE_PATH}/${ONE_SPA_ROUTE_ABOUT_GET_STARTED_PATH_NAME}`;
export const ONE_SPA_ROUTE_ABOUT_METHOD = `${ONE_SPA_ROUTE_ABOUT_BASE_PATH}/${ONE_SPA_ROUTE_ABOUT_METHOD_PATH_NAME}`;
export const ONE_SPA_ROUTE_ABOUT_FOOTPRINTS = `${ONE_SPA_ROUTE_ABOUT_BASE_PATH}/${ONE_SPA_ROUTE_ABOUT_FOOTPRINTS_PATH_NAME}`;
export const ONE_SPA_ROUTE_ABOUT_BENEFITS = `${ONE_SPA_ROUTE_ABOUT_BASE_PATH}/${ONE_SPA_ROUTE_ABOUT_BENEFITS_PATH_NAME}`;
export const ONE_SPA_ROUTE_ABOUT_STANDARDS = `${ONE_SPA_ROUTE_ABOUT_BASE_PATH}/${ONE_SPA_ROUTE_ABOUT_STANDARDS_PATH_NAME}`;
export const ONE_SPA_ROUTE_CHECKOUT_BASE_PATH = '/co2impact/checkout';
export const ONE_SPA_ROUTE_CHECKOUT_CONFIRMATION_PATH_NAME = 'confirmation/:orderId';
export const ONE_SPA_ROUTE_ROUTE_CHECKOUT_CONFIRMATION = `${ONE_SPA_ROUTE_CHECKOUT_BASE_PATH}/${ONE_SPA_ROUTE_CHECKOUT_CONFIRMATION_PATH_NAME}`;

export const ONE_SPA_ROUTE_EMISSIONS_VISUALIZED = `visualized/:reportType`;
export const ONE_SPA_ROUTE_EMISSIONS_BREAKDOWN = `breakdown/:reportType`;
export const ONE_SPA_ROUTE_DEFAULT_EMISSIONS_PATH = `${ONE_SPA_ROUTE_EMISSIONS_BASE_PATH}/visualized/ghg`;

export const co2ImpactroutePaths = {
  HOME: '/co2impact',
  EMISSIONS: ONE_SPA_ROUTE_EMISSIONS_BASE_PATH,
  ABOUT: ONE_SPA_ROUTE_ABOUT_BASE_PATH,
  ABOUT_GET_STARTED: ONE_SPA_ROUTE_ABOUT_GET_STARTED,
  ABOUT_METHOD: ONE_SPA_ROUTE_ABOUT_METHOD,
  ABOUT_FOOTPRINTS: ONE_SPA_ROUTE_ABOUT_FOOTPRINTS,
  ABOUT_BENEFITS: ONE_SPA_ROUTE_ABOUT_BENEFITS,
  ABOUT_STANDARDS: ONE_SPA_ROUTE_ABOUT_STANDARDS,
  PRICING: ONE_SPA_ROUTE_PRICING_BASE_PATH,
  CHECKOUT: ONE_SPA_ROUTE_CHECKOUT_BASE_PATH,
  EMISSIONS_PARAMS: ONE_SPA_ROUTE_EMISSIONS_PARAMS,
  CHECKOUT_CONFIRMATION: ONE_SPA_ROUTE_ROUTE_CHECKOUT_CONFIRMATION,
} as const;

export type Co2ImpactRoutePathKey = keyof typeof co2ImpactroutePaths;
export type Co2ImpactRoutePath = (typeof co2ImpactroutePaths)[Co2ImpactRoutePathKey];
