import { type PictogramIconProps } from './types';

// miscellaneous_02
export const Seedling = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.65918 21.5029C6.65918 20.6745 7.33075 20.0029 8.15918 20.0029H29.6426C42.3338 20.0029 52.6259 30.2951 52.6259 42.9863C52.6259 55.6775 42.3338 65.9697 29.6426 65.9697C16.9514 65.9697 6.65918 55.6775 6.65918 42.9863V21.5029ZM9.65918 23.0029V42.9858C9.65918 54.0201 18.6082 62.9697 29.6426 62.9697C40.6769 62.9697 49.6259 54.0207 49.6259 42.9863C49.6259 31.952 40.6769 23.0029 29.6426 23.0029H9.65918Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1515 44.4367C31.5292 43.6993 32.433 43.4077 33.1704 43.7853C50.3239 52.5703 62.0583 70.1855 62.0583 90.4973C62.0583 91.3257 61.3867 91.9973 60.5583 91.9973C59.7298 91.9973 59.0583 91.3257 59.0583 90.4973C59.0583 71.3784 48.0153 54.7585 31.8029 46.4555C31.0655 46.0779 30.7739 45.174 31.1515 44.4367Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.1238 35.123C75.6205 35.123 67.916 42.8275 67.916 52.3309C67.916 61.8342 75.6205 69.5387 85.1238 69.5387C94.6272 69.5387 102.332 61.8342 102.332 52.3309V35.123H85.1238ZM64.916 52.3309C64.916 41.1707 73.9636 32.123 85.1238 32.123H103.832C104.66 32.123 105.332 32.7946 105.332 33.623V52.3301C105.332 63.4903 96.284 72.5387 85.1238 72.5387C73.9636 72.5387 64.916 63.4911 64.916 52.3309Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.1448 52.3776C85.5226 53.1149 85.2313 54.0188 84.4941 54.3967C71.2049 61.2083 62.1572 74.8257 62.1572 90.4972C62.1572 91.3257 61.4857 91.9972 60.6572 91.9972C59.8288 91.9972 59.1572 91.3257 59.1572 90.4972C59.1572 73.6326 68.8964 59.0204 83.1257 51.727C83.8629 51.3491 84.7669 51.6404 85.1448 52.3776Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
