import { IconWrapper } from './style';
import type { IconProps } from '../../types';

export const Question = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39161 3H18.6084C19.951 3 21 4.19812 21 5.62665V15.5342C21 17.0088 19.951 18.1608 18.6084 18.1608H10.972L7.48951 20.8797C7.06993 21.2022 6.48252 20.8336 6.52448 20.2806V18.1608H5.39161C4.09091 18.1608 3 17.0088 3 15.5342V5.62665C3 4.19812 4.09091 3 5.39161 3ZM18.6084 4.38245H5.39161C4.76224 4.38245 4.25874 4.93543 4.25874 5.62665V15.5342C4.25874 16.2254 4.76224 16.8245 5.39161 16.8245H7.11189C7.48951 16.8245 7.74126 17.101 7.74126 17.5157V18.9903L10.3846 16.9627C10.4685 16.8706 10.6364 16.8245 10.7622 16.8245H18.6084C19.2378 16.8245 19.7832 16.2254 19.7832 15.5342V5.62665C19.7832 4.93543 19.2378 4.38245 18.6084 4.38245ZM10.9038 9.15C10.9038 9.45 10.6346 9.675 10.3269 9.675C10.0192 9.675 9.75 9.45 9.75 9.15C9.75 7.9125 10.7885 6.9375 12.0192 6.9375C13.25 6.9375 14.25 7.9125 14.25 9.15C14.25 9.975 13.75 10.725 12.9808 11.1C12.75 11.2125 12.5577 11.4 12.5577 11.6625V12C12.5577 12.3 12.3269 12.5625 12.0192 12.5625C11.7115 12.5625 11.4423 12.3 11.4423 12V11.6625C11.4423 10.8 11.9038 10.4625 12.5577 10.05C12.9038 9.8625 13.0962 9.525 13.0962 9.15C13.0962 8.55 12.6346 8.0625 12.0192 8.0625C11.4038 8.0625 10.9038 8.55 10.9038 9.15ZM12.5625 13.6875C12.5625 14.0184 12.2978 14.25 12 14.25C11.6691 14.25 11.4375 14.0184 11.4375 13.6875C11.4375 13.3897 11.6691 13.125 12 13.125C12.2978 13.125 12.5625 13.3897 12.5625 13.6875Z"
        fill={color ?? 'currentColor'}
      ></path>
    </svg>
  </IconWrapper>
);
