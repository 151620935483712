import { Question, spacing } from 'component-library';
import { useNavigate } from 'react-router-dom';
import { Link, LinkWrapper, LinkIconWrapper } from '../styles';
import { getStartUrl } from '../../utils/environment';
import type { TextType } from '../../HeaderMenu';

const ContactSupportLink = ({ text, closeMenu }: { text: TextType; closeMenu: () => void }) => {
  const url = `${getStartUrl()}support`;

  const navigate = useNavigate();

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate('/support/contact');
    closeMenu();
  };

  return (
    <LinkWrapper>
      <Link
        onClick={(e) => {
          handleOnClick(e);
        }}
        href={url}
      >
        <LinkIconWrapper>
          <Question width={spacing.small} height={spacing.small} />
        </LinkIconWrapper>
        {text.contactSupport}
      </Link>
    </LinkWrapper>
  );
};

export default ContactSupportLink;
