import { colors } from '../../themes/stena-recycling/colors';
import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const DownloadIcon = ({
  color = colors.primary.blue,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 15.4115C11.8795 15.4115 11.7673 15.3923 11.6635 15.3538C11.5597 15.3154 11.4609 15.2494 11.3672 15.1558L8.25775 12.0463C8.10908 11.8974 8.03567 11.7233 8.0375 11.524C8.0395 11.3247 8.11292 11.1474 8.25775 10.9922C8.41292 10.8372 8.59108 10.7572 8.79225 10.752C8.99358 10.7468 9.17183 10.8218 9.327 10.977L11.25 12.9V5.25C11.25 5.03717 11.3218 4.859 11.4655 4.7155C11.609 4.57183 11.7872 4.5 12 4.5C12.2128 4.5 12.391 4.57183 12.5345 4.7155C12.6782 4.859 12.75 5.03717 12.75 5.25V12.9L14.673 10.977C14.8218 10.8283 14.9984 10.7549 15.2028 10.7568C15.4073 10.7588 15.5871 10.8372 15.7423 10.9922C15.8871 11.1474 15.9621 11.3231 15.9672 11.5192C15.9724 11.7154 15.8974 11.8911 15.7423 12.0463L12.6328 15.1558C12.5391 15.2494 12.4403 15.3154 12.3365 15.3538C12.2327 15.3923 12.1205 15.4115 12 15.4115ZM6.30775 19.5C5.80258 19.5 5.375 19.325 5.025 18.975C4.675 18.625 4.5 18.1974 4.5 17.6923V15.7308C4.5 15.5179 4.57183 15.3398 4.7155 15.1962C4.859 15.0526 5.03717 14.9808 5.25 14.9808C5.46283 14.9808 5.641 15.0526 5.7845 15.1962C5.92817 15.3398 6 15.5179 6 15.7308V17.6923C6 17.7692 6.03208 17.8398 6.09625 17.9038C6.16025 17.9679 6.23075 18 6.30775 18H17.6923C17.7692 18 17.8398 17.9679 17.9038 17.9038C17.9679 17.8398 18 17.7692 18 17.6923V15.7308C18 15.5179 18.0718 15.3398 18.2155 15.1962C18.359 15.0526 18.5372 14.9808 18.75 14.9808C18.9628 14.9808 19.141 15.0526 19.2845 15.1962C19.4282 15.3398 19.5 15.5179 19.5 15.7308V17.6923C19.5 18.1974 19.325 18.625 18.975 18.975C18.625 19.325 18.1974 19.5 17.6923 19.5H6.30775Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);
