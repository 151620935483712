import AnnounceKit from 'announcekit-react';
import BellIconButton from '../HeaderMenu/BellIconButton';
import { WidgetWrapper } from './styles';

export const AnnounceKitComponent = () => {
  return (
    <WidgetWrapper>
      <AnnounceKit
        widget="https://announcekit.app/widgets/v2/1RW2re"
        widgetStyle={{ position: 'absolute', top: '0', right: '0' }}
      >
        <BellIconButton />
      </AnnounceKit>
    </WidgetWrapper>
  );
};
