import { useFlag } from '@unleash/proxy-client-react';
import { useCountries } from './useCountries';
import { useMemo } from 'react';

export const useBattery = () => {
  const swedish = useFlag('battery-pickup-se');
  const danish = useFlag('battery-pickup-dk');
  const finnish = useFlag('battery-pickup-fi');
  const norwegian = useFlag('battery-pickup-no');

  const { supportedCountries } = useCountries();

  const batterySupportedCountries = useMemo(() => {
    return {
      sweden: supportedCountries.sweden && swedish,
      denmark: supportedCountries.denmark && danish,
      finland: supportedCountries.finland && finnish,
      norway: supportedCountries.norway && norwegian,
    };
  }, [supportedCountries, swedish, danish, finnish, norwegian]);

  const batteryEnabled = useMemo(() => {
    if (supportedCountries.sweden && swedish) {
      return true;
    }

    if (supportedCountries.denmark && danish) {
      return true;
    }

    if (supportedCountries.finland && finnish) {
      return true;
    }

    if (supportedCountries.norway && norwegian) {
      return true;
    }

    return false;
  }, [supportedCountries, swedish, danish, finnish, norwegian]);

  return {
    batteryEnabled,
    batterySupportedCountries,
  };
};
