import { type PartnerResItem } from '@stenarecycling/customer-portal-types';
import { getPartnerDisplay } from '@stenarecycling/customer-portal-utils';
import { Paragraph } from 'component-library';
import { LocationWrapper, PartnerDescription } from './styles';
import { useTranslation } from 'react-i18next';

export type PartnerInfoProps = {
  partner?: PartnerResItem;
  boldTitle?: boolean;
  includeLocationCount?: boolean;
};

/*
This component is used to display partner information in a unified manner
*/
export const PartnerInfo = ({ partner, boldTitle, includeLocationCount }: PartnerInfoProps) => {
  const { t } = useTranslation();

  if (!partner) {
    return null;
  }

  const { nameAndText, addressAndId } = getPartnerDisplay(partner);

  const locationCountLabel =
    partner.locationCount > 1
      ? t('components.dropdown.locations')
      : t('components.dropdown.location');

  let description = addressAndId;

  if (includeLocationCount) {
    description = `${partner.locationCount} ${locationCountLabel}, ${addressAndId}`;
  }

  return (
    <LocationWrapper>
      <Paragraph bold={boldTitle} variant="large">
        {nameAndText}
      </Paragraph>
      <PartnerDescription>{description}</PartnerDescription>
    </LocationWrapper>
  );
};
