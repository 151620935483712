import { WithCustomerPortalApi } from '../../customerPortalApi';
import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { getApiUrl } from '../../utils/environment';

const apiUrl = getApiUrl();

type UpdateRoleIndustryProps = {
  industry?: string;
  roles: string[];
};

export const useUpdateRoleIndustry = WithCustomerPortalApi<
  UpdateRoleIndustryProps,
  UseMutationResult<undefined, Error, UpdateRoleIndustryProps>
>((fetchFn) => {
  const mutation = useMutation<undefined, Error, UpdateRoleIndustryProps>({
    mutationFn: async (body) => {
      const url = `${apiUrl}/usermanagement/user/role-industry`;

      const options = {
        method: 'PATCH',
        body: JSON.stringify(body),
      };

      await fetchFn({
        url: url.toString(),
        options,
      });
    },
  });

  return mutation;
});
