import { useState } from 'react';
import { useLocations } from '../Locations';

export const useFavoriteLocation = (
  serviceName?: 'services.order.pickup.name' | 'services.follow.up.name',
) => {
  const [favoriteLocationKeys, setFavoriteLocationKeys] =
    useState<string[]>(getFavoriteLocations());

  const { locationsById } = useLocations(serviceName);

  const addFavoriteLocation = (location: string, clear?: boolean) => {
    if (clear) {
      saveFavoriteLocations([location]);

      setFavoriteLocationKeys([location]);

      return;
    }

    saveFavoriteLocations([...favoriteLocationKeys, location]);

    setFavoriteLocationKeys((prev) => {
      if (prev.includes(location)) {
        return prev;
      }

      return [...prev, location];
    });
  };

  const favoriteLocations = favoriteLocationKeys
    .map((key) => locationsById[key])
    .filter((l) => !!l);

  const removeFavoriteLocation = (location: string) => {
    saveFavoriteLocations(favoriteLocationKeys.filter((l) => l !== location));
    setFavoriteLocationKeys((prev) => prev.filter((l) => l !== location));
  };

  return {
    favoriteLocationKeys,
    favoriteLocations,
    addFavoriteLocation,
    removeFavoriteLocation,
  };
};

const saveFavoriteLocations = (locations: string[]) => {
  localStorage.setItem('favoriteLocations', JSON.stringify(locations));
};

const getFavoriteLocations = (): string[] => {
  const favoriteLocations = localStorage.getItem('favoriteLocations');

  if (!favoriteLocations) {
    return [];
  }

  return JSON.parse(favoriteLocations) as string[];
};
