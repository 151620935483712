import { Bell, colors, spacing } from 'component-library';
import { BellIconButtonWrapper } from './styles';

const BellIconButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <BellIconButtonWrapper {...props}>
      <Bell color={colors.base.white} width={spacing.small} height={spacing.small} />
    </BellIconButtonWrapper>
  );
};

export default BellIconButton;
