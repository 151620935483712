import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const Bell = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M18 8C18 4.68629 15.3137 2 12 2C8.68629 2 6 4.68629 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(0, 0)"
      ></path>
      <path
        d="M13.73 21C13.3722 21.6168 12.7131 21.9965 12 21.9965C11.287 21.9965 10.6278 21.6168 10.27 21"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(0, 0)"
      ></path>
    </svg>
  </IconWrapper>
);
