import { type PictogramIconProps } from './types';

// arrows_03
export const ArrowsCircle = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.6094 0.43934C52.1952 -0.146447 53.1449 -0.146447 53.7307 0.43934L62.6107 9.31934C62.8921 9.60075 63.0502 9.98245 63.05 10.3804C63.0499 10.7784 62.8917 11.16 62.6101 11.4413L53.7301 20.3113C53.144 20.8967 52.1942 20.8962 51.6088 20.3101C51.0233 19.7239 51.0239 18.7742 51.61 18.1887L59.4281 10.3794L51.6094 2.56066C51.0236 1.97487 51.0236 1.02513 51.6094 0.43934Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.1631 74.2211C91.9635 74.0073 92.7856 74.4828 92.9994 75.2832L95.852 85.9629L106.532 83.1014C107.332 82.887 108.155 83.3619 108.369 84.1621C108.583 84.9623 108.109 85.7848 107.308 85.9992L95.1784 89.2492C94.7939 89.3522 94.3843 89.2982 94.0397 89.099C93.695 88.8999 93.4437 88.5719 93.341 88.1874L90.101 76.0574C89.8872 75.257 90.3627 74.4349 91.1631 74.2211Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03084 57.2174C7.9896 29.6195 31.1442 8.02127 58.7426 8.99036C59.5705 9.01943 60.2181 9.71416 60.189 10.5421C60.1599 11.37 59.4652 12.0176 58.6373 11.9885C32.6957 11.0776 10.9303 31.3794 10.029 57.3215C9.89333 61.3077 10.2523 65.194 11.0562 68.9233C11.2308 69.7331 10.7159 70.5312 9.90604 70.7057C9.09622 70.8803 8.2982 70.3654 8.12362 69.5555C7.26767 65.5852 6.88659 61.4519 7.0308 57.2184L7.03084 57.2174Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.3229 12.8484C68.5773 12.06 69.4226 11.6271 70.211 11.8815C74.0762 13.1286 77.8486 14.8603 81.4451 17.1068C104.868 31.736 111.981 62.5816 97.3527 86.0036C96.9138 86.7062 95.9885 86.9201 95.2858 86.4812C94.5832 86.0424 94.3693 85.1171 94.8082 84.4144C108.56 62.3964 101.872 33.402 79.8558 19.6512L79.8557 19.6512C76.4722 17.5377 72.9246 15.9094 69.2898 14.7365C68.5014 14.4821 68.0685 13.6368 68.3229 12.8484Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9859 78.9249C12.7174 78.5361 13.6256 78.8139 14.0144 79.5455C26.1955 102.464 54.657 111.166 77.5754 98.9751L77.5757 98.975C81.0953 97.1037 84.2841 94.8452 87.1139 92.2868C87.7284 91.7312 88.677 91.779 89.2325 92.3935C89.7881 93.008 89.7403 93.9565 89.1258 94.5121C86.1157 97.2336 82.7246 99.6351 78.9843 101.624C54.6028 114.593 24.3243 105.335 11.3653 80.9534C10.9765 80.2219 11.2544 79.3137 11.9859 78.9249Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3901 76.5915C10.7347 76.3926 11.1442 76.3388 11.5285 76.4418L23.6485 79.6918C24.4487 79.9064 24.9234 80.729 24.7088 81.5291C24.4943 82.3293 23.6717 82.804 22.8715 82.5894L12.2006 79.728L9.33892 90.4088C9.12452 91.209 8.30202 91.6839 7.50182 91.4695C6.70161 91.2551 6.22673 90.4326 6.44113 89.6324L9.69113 77.5024C9.7941 77.1181 10.0455 76.7904 10.3901 76.5915Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
