import { type PictogramIconProps } from './types';

// energy_11
export const LightningBolt = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.5143 17.6678C51.7744 17.177 52.2843 16.8701 52.8398 16.8701H77.0373C77.5861 16.8701 78.091 17.1698 78.3539 17.6514C78.6169 18.1331 78.5958 18.7199 78.2989 19.1815L61.2722 45.6577L77.1821 42.9652C77.8135 42.8584 78.4431 43.1641 78.7495 43.7264C79.0559 44.2887 78.9716 44.9835 78.5396 45.4562L33.4038 94.8389C32.9059 95.3836 32.0857 95.4853 31.4699 95.0786C30.8541 94.6719 30.6256 93.8777 30.9311 93.2059L47.1862 57.4633L32.008 59.5597C31.4502 59.6367 30.8963 59.395 30.5736 58.9336C30.2509 58.4723 30.2137 57.869 30.4773 57.3715L51.5143 17.6678ZM53.7425 19.8701L34.5 56.187L49.474 54.1188C50.0157 54.044 50.5551 54.2698 50.8819 54.7081C51.2087 55.1465 51.2711 55.7279 51.0447 56.2257L37.5888 85.8133L73.3834 46.6507L58.4234 49.1824C57.8376 49.2815 57.2486 49.0256 56.9213 48.5298C56.594 48.034 56.5901 47.3918 56.9115 46.8921L74.2893 19.8701H53.7425Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
