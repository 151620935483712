import { type TFunction } from 'i18next';

export const sortAlphabeticallyOtherLast = (
  a: string,
  b: string,
  t: TFunction,
  keyPrefix: string,
) => {
  if (a === 'other') return 1;
  if (b === 'other') return -1;

  const aLabel = t(`${keyPrefix}.${a}`);
  const bLabel = t(`${keyPrefix}.${b}`);

  return aLabel.localeCompare(bLabel);
};
