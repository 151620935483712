import { type PictogramIconProps } from './types';

// miscellaneous_05
export const Route = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 52.3995C16.5 41.7329 24.9003 33.0195 35.36 33.0195H35.55C45.9997 33.0195 54.41 41.7429 54.41 52.3995V52.5895C54.41 55.3864 53.2106 59.1203 51.5789 62.9755C49.9217 66.8909 47.7229 71.1451 45.5402 75.061C43.3548 78.9817 41.1715 82.5883 39.5355 85.2133C38.7171 86.5264 38.0348 87.5954 37.5565 88.337C37.3173 88.7079 37.1291 88.9969 37.0002 89.1938C36.9358 89.2923 36.8863 89.3677 36.8527 89.4188L36.8143 89.477L36.8009 89.4973C36.8007 89.4976 36.8006 89.4978 35.55 88.6695L36.8009 89.4973C36.5231 89.9167 36.0531 90.1695 35.55 90.1695H35.36C34.8569 90.1695 34.3873 89.9172 34.1094 89.4978L35.36 88.6695C34.1094 89.4978 34.1096 89.4981 34.1094 89.4978L34.0957 89.477L34.0573 89.4188C34.0237 89.3677 33.9742 89.2923 33.9097 89.1938C33.7809 88.9969 33.5927 88.7079 33.3535 88.337C32.8752 87.5954 32.1929 86.5264 31.3745 85.2133C29.7385 82.5883 27.5552 78.9817 25.3698 75.061C23.1871 71.1451 20.9883 66.8909 19.3311 62.9755C17.6994 59.1203 16.5 55.3864 16.5 52.5895V52.3995ZM35.455 86.0576C35.0339 85.3993 34.5135 84.5781 33.9205 83.6266C32.3015 81.0287 30.1448 77.4659 27.9902 73.6004C25.8329 69.7301 23.6917 65.5812 22.0939 61.8062C20.4706 57.9711 19.5 54.7275 19.5 52.5895V52.3995C19.5 43.306 26.6397 36.0195 35.36 36.0195H35.55C44.2603 36.0195 51.41 43.316 51.41 52.3995V52.5895C51.41 54.7275 50.4394 57.9711 48.8161 61.8062C47.2183 65.5812 45.0771 69.7301 42.9198 73.6004C40.7652 77.4659 38.6085 81.0287 36.9895 83.6266C36.3965 84.5781 35.8761 85.3993 35.455 86.0576Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4602 45.7304C32.4171 45.7304 29.9502 48.1973 29.9502 51.2404C29.9502 54.2835 32.4171 56.7504 35.4602 56.7504C38.5033 56.7504 40.9702 54.2835 40.9702 51.2404C40.9702 48.1973 38.5033 45.7304 35.4602 45.7304ZM27.9502 51.2404C27.9502 47.0927 31.3125 43.7304 35.4602 43.7304C39.6079 43.7304 42.9702 47.0927 42.9702 51.2404C42.9702 55.3881 39.6079 58.7504 35.4602 58.7504C31.3125 58.7504 27.9502 55.3881 27.9502 51.2404Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.7197 38.1199C64.7197 29.7967 71.2711 22.9999 79.4297 22.9999H79.5797C87.7284 22.9999 94.2897 29.8067 94.2897 38.1199V38.2699C94.2897 40.4519 93.3543 43.3643 92.0816 46.3713C90.789 49.4251 89.0741 52.743 87.3717 55.7971C85.6672 58.8549 83.9642 61.6676 82.6883 63.7148C82.05 64.7388 81.5178 65.5725 81.1447 66.1508C80.9582 66.44 80.8113 66.6655 80.7109 66.819C80.6606 66.8958 80.622 66.9546 80.5958 66.9945L80.558 67.0517L80.5554 67.0557C80.5552 67.0559 80.5551 67.0561 79.5797 66.4099L80.5554 67.0557C80.3387 67.3828 79.9721 67.5799 79.5797 67.5799H79.4297C79.0373 67.5799 78.671 67.3832 78.4543 67.0561L79.4297 66.4099C78.4543 67.0561 78.4545 67.0563 78.4543 67.0561L78.4514 67.0517L78.4137 66.9945C78.3976 66.9701 78.3769 66.9386 78.3518 66.9003C78.3358 66.8759 78.3181 66.8488 78.2986 66.819C78.1981 66.6655 78.0513 66.44 77.8647 66.1508C77.4917 65.5725 76.9595 64.7388 76.3212 63.7148C75.0452 61.6676 73.3423 58.8549 71.6378 55.7971C69.9354 52.743 68.2204 49.4251 66.9279 46.3713C65.6552 43.3643 64.7197 40.4519 64.7197 38.2699V38.1199ZM79.5047 64.3743C79.1761 63.8606 78.7699 63.2197 78.307 62.477C77.0442 60.451 75.3622 57.6725 73.6817 54.6578C71.9991 51.6393 70.329 48.4035 69.0828 45.4592C67.8168 42.468 67.0597 39.938 67.0597 38.2699V38.1199C67.0597 31.0232 72.6284 25.3399 79.4297 25.3399H79.5797C86.3711 25.3399 91.9497 31.0332 91.9497 38.1199V38.2699C91.9497 39.938 91.1927 42.468 89.9266 45.4592C88.6804 48.4035 87.0104 51.6393 85.3278 54.6578C83.6473 57.6725 81.9652 60.451 80.7024 62.477C80.2395 63.2197 79.8333 63.8606 79.5047 64.3743Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.4997 32.9095C77.1248 32.9095 75.1996 34.8347 75.1996 37.2095C75.1996 39.5844 77.1248 41.5095 79.4997 41.5095C81.8745 41.5095 83.7997 39.5844 83.7997 37.2095C83.7997 34.8347 81.8745 32.9095 79.4997 32.9095ZM73.6396 37.2095C73.6396 33.9732 76.2633 31.3495 79.4997 31.3495C82.736 31.3495 85.3597 33.9732 85.3597 37.2095C85.3597 40.4459 82.736 43.0695 79.4997 43.0695C76.2633 43.0695 73.6396 40.4459 73.6396 37.2095Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7598 88.5195C39.7598 87.9672 40.2075 87.5195 40.7598 87.5195H78.4598C79.0121 87.5195 79.4598 87.9672 79.4598 88.5195C79.4598 89.0718 79.0121 89.5195 78.4598 89.5195H40.7598C40.2075 89.5195 39.7598 89.0718 39.7598 88.5195Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.46 77.6201C77.46 77.0678 77.9077 76.6201 78.46 76.6201C82.0222 76.6201 84.91 79.5078 84.91 83.0701C84.91 86.6324 82.0222 89.5201 78.46 89.5201C77.9077 89.5201 77.46 89.0724 77.46 88.5201C77.46 87.9678 77.9077 87.5201 78.46 87.5201C80.9177 87.5201 82.91 85.5278 82.91 83.0701C82.91 80.6124 80.9177 78.6201 78.46 78.6201C77.9077 78.6201 77.46 78.1724 77.46 77.6201Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.6104 77.6104C58.6104 77.0581 59.0581 76.6104 59.6104 76.6104H78.4604C79.0126 76.6104 79.4604 77.0581 79.4604 77.6104C79.4604 78.1626 79.0126 78.6104 78.4604 78.6104H59.6104C59.0581 78.6104 58.6104 78.1626 58.6104 77.6104Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.6099 67.71C57.1522 67.71 55.1599 69.7022 55.1599 72.16C55.1599 74.6177 57.1522 76.61 59.6099 76.61C60.1622 76.61 60.6099 77.0577 60.6099 77.61C60.6099 78.1622 60.1622 78.61 59.6099 78.61C56.0476 78.61 53.1599 75.7222 53.1599 72.16C53.1599 68.5977 56.0476 65.71 59.6099 65.71C60.1622 65.71 60.6099 66.1577 60.6099 66.71C60.6099 67.2622 60.1622 67.71 59.6099 67.71Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.6104 66.71C58.6104 66.1577 59.0581 65.71 59.6104 65.71H74.4904C75.0426 65.71 75.4904 66.1577 75.4904 66.71C75.4904 67.2622 75.0426 67.71 74.4904 67.71H59.6104C59.0581 67.71 58.6104 67.2622 58.6104 66.71Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
