import { type LocationListItemsType } from './types';

const isSearchable = (item: LocationListItemsType) => {
  const isNBP = item.meta?.source === 'FNO';
  const isLocation = item.meta?.isLocation;

  if (isNBP && !isLocation) {
    return false;
  }

  return true;
};

const itemSearchFilter = (item: LocationListItemsType, searchLower: string) => {
  const searchable = isSearchable(item);

  if (!searchable) {
    return false;
  }

  const description = item.meta?.description ?? '';

  const itemString = `${item.label}${item.value}${description}`.toLowerCase();

  return itemString.includes(searchLower);
};

const itemIsInSearch = (item: LocationListItemsType, searchLower: string): boolean => {
  if (itemSearchFilter(item, searchLower)) {
    return true; // Short-circuit if parent item is in search
  }

  const isFNO = item.meta?.source === 'FNO';

  if (!isFNO) {
    for (const child of item.children ?? []) {
      if (itemSearchFilter(child, searchLower)) {
        return true; // Short-circuit if any child item is in search
      }
    }
  }

  return false;
};

export const filterItems = (
  items: LocationListItemsType[],
  searchLower: string,
): LocationListItemsType[] => {
  const filteredItems: LocationListItemsType[] = [];

  for (const item of items) {
    const isInSearch = itemIsInSearch(item, searchLower);

    if (isInSearch) {
      const filteredChildren = filterItems(item.children ?? [], searchLower);

      filteredItems.push({ ...item, children: filteredChildren });
    } else {
      const filteredChildren = filterItems(item.children ?? [], searchLower);

      if (filteredChildren.length > 0) {
        filteredItems.push({ ...item, children: filteredChildren });
      }
    }
  }

  return filteredItems;
};

// Flatten items to one level
const flattenItems = (items: LocationListItemsType[]): LocationListItemsType[] => {
  const flatItems: LocationListItemsType[] = [];

  for (const item of items) {
    if (item.type === 'value') flatItems.push(item);

    if (item.children.length > 0) {
      // eslint-disable-next-line prefer-spread -- Bad performance
      flatItems.push.apply(flatItems, flattenItems(item.children));
    }
  }

  return flatItems;
};

const locationSearchItemsFlat = (items: LocationListItemsType[], search: string) => {
  const flatItems = flattenItems(items);
  const flatNoChilds = flatItems.map(
    (item) =>
      ({
        ...item,
        children: [],
        meta: { ...item.meta, childCount: 0 },
      }) as LocationListItemsType,
  );

  return filterItems(flatNoChilds, search.toLowerCase());
};

export const locationSearchItems = async (
  inputItems: LocationListItemsType[],
  search: string,
  isMultiSelect: boolean,
): Promise<LocationListItemsType[]> => {
  const searchLower = search.toLowerCase();
  const items = [...inputItems];

  if (!isMultiSelect) {
    const fnoItems = items.filter((item) => item.meta?.source === 'FNO');
    let fnoResults: LocationListItemsType[] = [];

    if (fnoItems.length > 0) {
      fnoResults = locationSearchItemsFlat(fnoItems, searchLower).map(
        convertListItemToFullNameItem,
      );
    }

    const allExceptFNO = items.filter((item) => item.meta?.source !== 'FNO');

    const filteredItems = locationSearchItemsFlat(allExceptFNO, searchLower);

    return [...fnoResults, ...filteredItems];
  }

  return filterItems(items, searchLower);
};

const convertListItemToFullNameItem = (item: LocationListItemsType): LocationListItemsType => {
  const fullName = item.altLabel ?? item.label;

  return {
    ...item,
    label: fullName,
  };
};
