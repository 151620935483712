import { useQuery } from '@tanstack/react-query';
import { useAuth0, useTransactionId } from 'core';
import { config } from '../../config/config';

export function useApi<ResponseType = unknown>({
  url,
  fetchOptions,
}: {
  url: string;
  fetchOptions?: RequestInit;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const transactionId = useTransactionId();

  const fetchQuery = async () => {
    const accessToken = await getAccessTokenSilently();
    const data = await apiFetch(url, accessToken, transactionId, fetchOptions);

    return data.json() as Promise<ResponseType>;
  };

  return useQuery({
    queryKey: [url, fetchOptions],
    queryFn: fetchQuery,
  });
}

export const apiFetch = async (
  url: string,
  accessToken: string,
  transactionId: string,
  fetchOptions?: RequestInit,
  allowTimeout?: boolean,
): Promise<Response> => {
  if (!accessToken) {
    throw new Error('No access token');
  }

  return fetch(config.RECYCLING_API_URL + url, {
    ...fetchOptions,
    headers: {
      // Add the Authorization header to the existing headers
      Authorization: `Bearer ${accessToken}`,
      'X-Transaction-ID': transactionId,
      ...fetchOptions?.headers,
    },
  }).then((response) => {
    if (!response.ok) {
      if (response.status === 504 && allowTimeout) {
        return response;
      }
      throw new Error(`Failed to fetch ${url}`);
    }

    return response;
  });
};
