import { useContext } from 'react';
import { DropdownContexts } from '../../../../context/DropdownContext';
import { ListItemSelectAll } from '../../components/ListItemSelectAll';
import { Divider, ListWrapper } from '../../style';
import { GroupList } from './components/GroupList/GroupList';

type MultiSelectListProps = {
  showSelectAll?: boolean;
};

export const MultiSelectList = ({ showSelectAll = true }: MultiSelectListProps) => {
  const { items } = useContext(DropdownContexts.useItems);
  const { searchResults, searchActive } = useContext(DropdownContexts.useSearch);

  const itemsToRender = searchActive ? searchResults : items;

  return (
    <ListWrapper>
      {showSelectAll && (
        <>
          <ListItemSelectAll />
          <Divider />
        </>
      )}

      {itemsToRender.map((item) => (
        <GroupList key={item.label + item.value} item={item} />
      ))}
    </ListWrapper>
  );
};
