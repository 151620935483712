import { useContext, useCallback } from 'react';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { DropdownContexts } from '../../../context/DropdownContext';
import { Description, ListWrapper, SimpleListItemWrapper } from '../style';
import { ListItemSelectSimple } from '../components/ListItemSelectSimple';

export const SimpleSelectList = () => {
  const { items } = useContext(DropdownContexts.useItems);
  const { searchActive, searchResults } = useContext(DropdownContexts.useSearch);

  const itemsToRender = searchActive ? searchResults : items;

  const getDesc = useCallback((item: ListDropdownItem) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const desc = item.meta?.description;

    if (desc) {
      if (Array.isArray(desc)) {
        return desc.map((desc: string) => <Description key={desc}>{desc}</Description>);
      }

      return <Description>{desc}</Description>;
    }

    return null;
  }, []);

  return (
    <ListWrapper>
      {itemsToRender.map((item) => (
        <ListItemSelectSimple key={item.value + item.label} item={item}>
          <SimpleListItemWrapper>
            <span>{item.label}</span>
            {getDesc(item)}
          </SimpleListItemWrapper>
        </ListItemSelectSimple>
      ))}
    </ListWrapper>
  );
};
