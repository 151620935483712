import { User, colors, spacing } from 'component-library';
import { UserIconButtonWrapper } from './styles';

const UserIconButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <UserIconButtonWrapper {...props}>
      <User color={colors.base.white} width={spacing.small} height={spacing.small} />
    </UserIconButtonWrapper>
  );
};

export default UserIconButton;
