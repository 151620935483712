import { useCallback, useEffect, useRef, useState, type ReactNode } from 'react';
import { spacing } from '../../../themes/stena-recycling/spacing';
import { Chevron } from '../../Icons/Chevron';
import { SkeletonLoader } from '../../Feedback/SkeletonLoader';
import { Paragraph } from '../../Typography';
import { isDropdownOptionGroup } from '../../helpers/typeHelper';
import {
  DropdownError,
  DropdownHeader,
  DropdownHeaderWrapper,
  DropdownIcon,
  DropdownLabel,
  DropdownList,
  DropdownListItem,
  DropdownWrapper,
  GroupLabelWrapper,
  IconWrapper,
  InfoMessage,
  LoadingWrapper,
  SubLabelWrapper,
} from './style';

export type DropdownProps = {
  options: DropdownOption[] | DropdownOptionGroup[];
  value: string;
  onChange: (option: DropdownOption) => void;
  label?: string;
  placeholder?: string;
  useScroll?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  info?: string;
  isLoading?: boolean;
  icon?: ReactNode;
  hideChevron?: boolean;
  testId?: string;
};

export type DropdownOption = {
  value: string;
  label: string;
  subLabels?: string[];
};

export type DropdownOptionGroup = {
  label?: string;
  options: DropdownOption[];
};

export const Dropdown = ({
  options,
  value = '',
  onChange,
  label = '',
  placeholder,
  useScroll = false,
  disabled,
  errorMessage = '',
  info,
  isLoading,
  icon,
  hideChevron,
  testId = '',
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownHeaderRef = useRef<HTMLDivElement>(null);
  const showPlaceholder = value.length <= 0 && placeholder && placeholder.length > 0 ? true : false;

  const clickOutside = (e: MouseEvent) => {
    if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelect = (option: DropdownOption) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
  }, [dropdownHeaderRef]);

  return (
    <DropdownWrapper data-testid={testId} ref={dropdownWrapperRef}>
      <div ref={dropdownHeaderRef}>
        {label && <DropdownLabel>{label}</DropdownLabel>}
        {isLoading ? (
          <LoadingWrapper>
            <SkeletonLoader height={spacing.small} />
          </LoadingWrapper>
        ) : (
          <DropdownHeaderWrapper
            data-testid="dropdown-header"
            onClick={toggleOpen}
            error={errorMessage.length > 0 ? true : false}
            isPlaceholder={showPlaceholder}
            disabled={disabled}
            isOpen={isOpen}
          >
            {icon && <IconWrapper>{icon}</IconWrapper>}
            <DropdownHeader>
              {showPlaceholder
                ? placeholder
                : getOptionByValue(
                    value,
                    isDropdownOptionGroup(options) ? getOptionsFromGroups(options) : options,
                  )?.label}
            </DropdownHeader>
            {!hideChevron && (
              <DropdownIcon open={isOpen}>
                <Chevron />
              </DropdownIcon>
            )}
          </DropdownHeaderWrapper>
        )}
      </div>
      {errorMessage.length > 0 && <DropdownError>{errorMessage}</DropdownError>}
      {info && <InfoMessage>{info}</InfoMessage>}
      {isDropdownOptionGroup(options) && options.length > 0 && (
        <DropdownList
          isOpen={isOpen}
          useScroll={useScroll}
          hasLabel={label.length > 0 ? true : false}
        >
          {options.map((group) => (
            <div key={group.label ?? JSON.stringify(group)}>
              {group.label && (
                <GroupLabelWrapper>
                  <span>{group.label}</span>
                </GroupLabelWrapper>
              )}

              {group.options.map((option) => (
                <DropdownListItem
                  key={option.value}
                  data-testid={option.value}
                  onClick={() => {
                    handleSelect(option);
                  }}
                >
                  {option.label}
                  {option.subLabels && option.subLabels.length > 1 && (
                    <SubLabelWrapper>
                      {option.subLabels.map((subLabel) => (
                        <Paragraph key={subLabel} variant="medium">
                          {subLabel}
                        </Paragraph>
                      ))}
                    </SubLabelWrapper>
                  )}
                </DropdownListItem>
              ))}
            </div>
          ))}
        </DropdownList>
      )}
      {!isDropdownOptionGroup(options) && options.length > 0 && (
        <DropdownList
          isOpen={isOpen}
          useScroll={useScroll}
          hasLabel={label.length > 0 ? true : false}
        >
          {options.length > 0 &&
            options.map((option) => (
              <DropdownListItem
                key={option.value}
                data-testid={option.value}
                onClick={() => {
                  handleSelect(option);
                }}
              >
                {option.label}
                {option.subLabels && option.subLabels.length > 0 && (
                  <SubLabelWrapper>
                    {option.subLabels.map((subLabel) => (
                      <Paragraph key={subLabel} variant="medium">
                        {subLabel}
                      </Paragraph>
                    ))}
                  </SubLabelWrapper>
                )}
              </DropdownListItem>
            ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

const getOptionByValue = (value: string, options: DropdownOption[]) => {
  return options.find((option) => option.value === value);
};

const getOptionsFromGroups = (groups: DropdownOptionGroup[]) => {
  return groups.flatMap((obj) => obj.options);
};
