import { useTranslation } from 'react-i18next';
import { type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';
import { useMemo } from 'react';
import { type DropdownProviderProps, DropdownV2 } from '../DropdownV2';
import { locationSearchItems } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/utils';
import { LocationPickerSearch } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/components/LocationPickerSearch/DropdownSearch';
import { LocationPickerList } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/LocationPickerList';
import { partnerTreesToListDropdown } from './utils';
import {
  InactiveStateProvider,
  useInactiveState,
} from './hooks/useLocationPicker/useInactiveState';

type LocationPickerPickedProps = Pick<
  DropdownProviderProps,
  | 'required'
  | 'selectedValues'
  | 'loading'
  | 'onChange'
  | 'errorMessage'
  | 'error'
  | 'multiSelect'
  | 'disabled'
  | 'autoSelect'
> & {
  label?: string;
};

export type LocationGroup = {
  rootPartnerId: string;
  allPartnerIds: string[];
};

export type LocationPickerProps = LocationPickerPickedProps & {
  locations: PartnerTreeNodeV2[];
  hideInactiveLocations: boolean;
  showInactiveToggle?: boolean;
  onInactiveToggle?: (hideInactive: boolean) => void;
  allowSelectingBusinessPartners?: boolean;
  hideNBPPrefix?: boolean;
  onLocationGroupChange?: (groups: LocationGroup[]) => void;
};
const LocationPickerProviderLess = (props: LocationPickerProps) => {
  const { t } = useTranslation();

  const { hideInactiveLocations } = useInactiveState();

  const dropdownItems = useMemo(() => {
    return partnerTreesToListDropdown(
      props.locations,
      hideInactiveLocations,
      0,
      !!props.allowSelectingBusinessPartners,
      props.hideNBPPrefix,
    );
  }, [hideInactiveLocations, props]);

  return (
    <DropdownV2
      error={props.error}
      items={dropdownItems}
      disabled={props.disabled}
      loading={props.loading}
      onChange={props.onChange}
      errorMessage={props.errorMessage}
      selectedValues={props.selectedValues}
      label={props.label ?? t('filter.businessPartners')}
      searchPlaceholder={t('filter.searchLocation')}
      required={props.required}
      multiSelect={props.multiSelect}
      autoSelect={props.autoSelect}
      customSearch={(inputItems, search) =>
        locationSearchItems(inputItems, search, !!props.multiSelect)
      }
      onGroupChange={(groups) => {
        if (props.onLocationGroupChange) {
          props.onLocationGroupChange(
            groups.map((group) => ({
              rootPartnerId: group.rootItemValue,
              allPartnerIds: group.allValues,
            })),
          );
        }
      }}
      placeholder={t('filter.businessPartnersPlaceholder')}
    >
      <LocationPickerSearch />

      <LocationPickerList />
    </DropdownV2>
  );
};

export const LocationPicker = (props: LocationPickerProps) => {
  const showInactiveToggle = !!props.showInactiveToggle && !!props.multiSelect;

  return (
    <InactiveStateProvider
      locations={props.locations}
      showInactiveToggle={showInactiveToggle}
      hideInactiveLocations={!!props.hideInactiveLocations}
      onInactiveToggle={props.onInactiveToggle}
    >
      <LocationPickerProviderLess {...props} />
    </InactiveStateProvider>
  );
};
