import React, { useContext } from 'react';
import { Divider, GroupLabel, ListWrapper } from '../style';
import { ListItemSelectSimple } from '../components/ListItemSelectSimple';
import { DropdownContexts } from '../../../context/DropdownContext';

export const GroupedSelectList = () => {
  const { items } = useContext(DropdownContexts.useItems);
  const { searchResults, searchActive } = useContext(DropdownContexts.useSearch);

  const itemsToRender = searchActive ? searchResults : items;

  return (
    <ListWrapper>
      {itemsToRender.map((group) => (
        <React.Fragment key={group.label}>
          {group.label.length > 0 && (
            <>
              <GroupLabel>{group.label}</GroupLabel>
              <Divider />
            </>
          )}

          {group.children.map((item) => (
            <ListItemSelectSimple item={item} key={`${item.label}${item.value}`}>
              {item.label}
            </ListItemSelectSimple>
          ))}
        </React.Fragment>
      ))}
    </ListWrapper>
  );
};
