import { useTranslation } from 'react-i18next';
import { useProfileV2 } from '../Profile';
import { Heading, ModalV2, Paragraph, useSnackbar } from 'component-library';
import { DropdownWrapper, FormRow } from './styles';
import { RoleDropdown } from './RoleDropdown';
import { IndustryDropdown } from './IndustryDropdown';
import { useEffect, useMemo, useState } from 'react';
import { useHasSeenRoleIndustry } from './hooks/useHasSeenRoleIndustry';
import { useAuth0 } from '@auth0/auth0-react';
import { useUpdateRoleIndustry } from '../hooks/useRoleIndustry';
import { useFlag } from '@unleash/proxy-client-react';

export const RoleIndustrySurvey = () => {
  const { t } = useTranslation();

  const { profile, loading, updateProfile } = useProfileV2();

  const [industry, setIndustry] = useState<string | undefined>();
  const [roles, setRoles] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [hasSeen, setHasSeen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();

  const shouldShowRoleIndustryModal = useFlag('role-industry-survey');

  const seenRolesIndustrySurveyMutation = useHasSeenRoleIndustry();

  const isDirty = useMemo(() => {
    return !!industry && roles.length > 0;
  }, [roles, industry]);

  useEffect(() => {
    if (shouldShowRoleIndustryModal && profile && !profile.seenRolesIndustrySurvey && !hasSeen) {
      setOpen(true);
      setHasSeen(true);
      seenRolesIndustrySurveyMutation.mutate();
    }
  }, [hasSeen, profile, seenRolesIndustrySurveyMutation, shouldShowRoleIndustryModal]);

  const updateRolesIndustry = useUpdateRoleIndustry();

  const onSave = async () => {
    if (!roles.length && !industry) return;

    try {
      await updateRolesIndustry.mutateAsync({
        roles: roles,
        industry,
      });

      setOpen(false);

      await getAccessTokenSilently({ cacheMode: 'off' });
      const updateProfilePromise = updateProfile();

      showSnackbar({
        title: t('settings.save.success.title'),
        description: t('settings.save.success.description', { email: profile?.email }),
        severity: 'success',
      });

      await updateProfilePromise;
    } catch (e) {
      console.error(e);
      showSnackbar({
        title: t('settings.save.error.title'),
        description: t('settings.save.error.description'),
        severity: 'error',
      });
    }
  };

  if (
    !shouldShowRoleIndustryModal ||
    !profile ||
    loading ||
    profile.roles.length ||
    profile.industry ||
    profile.seenRolesIndustrySurvey
  ) {
    return null;
  }

  return (
    <ModalV2
      open={open}
      closeOnClickOutside={false}
      closeOnEscape={false}
      primaryAction={{
        label: t('role.industry.survey.submit'),
        disabled: !isDirty,
        isLoading: updateRolesIndustry.isPending,
        action: async () => {
          await onSave();
        },
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Heading as="h1" variant="large" mb="xsmall">
        {t('role.industry.survey.title')}
      </Heading>
      <Paragraph mb="small">{t('role.industry.survey.text')}</Paragraph>
      <FormRow>
        <DropdownWrapper>
          <RoleDropdown roles={roles} setRoles={setRoles} />
        </DropdownWrapper>
        <DropdownWrapper>
          <IndustryDropdown industry={industry} setIndustry={setIndustry} />
        </DropdownWrapper>
      </FormRow>
    </ModalV2>
  );
};
