import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const ArrowLeft = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
    >
      <path
        id="Path"
        d="M21.5 12H4"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M10 19L3 12L10 5"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconWrapper>
);
