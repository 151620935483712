import { type PictogramIconProps } from './types';

// office_06
export const Checklist = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5244 12.3457C20.5244 11.5173 21.196 10.8457 22.0244 10.8457H89.975C90.8035 10.8457 91.475 11.5173 91.475 12.3457V99.6543C91.475 100.483 90.8035 101.154 89.975 101.154H22.0244C21.196 101.154 20.5244 100.483 20.5244 99.6543V12.3457ZM23.5244 13.8457V98.1543H88.475V13.8457H23.5244Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5216 25.4523C50.1921 26.129 50.1871 27.2212 49.5103 27.8918L38.7449 38.5584C38.0739 39.2232 36.9931 39.2248 36.3202 38.562L29.7029 32.0435C29.0242 31.3749 29.016 30.2827 29.6846 29.604C30.3532 28.9253 31.4453 28.9171 32.124 29.5857L37.5272 34.9082L47.0821 25.441C47.7588 24.7705 48.851 24.7755 49.5216 25.4523Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9814 28.543C55.9814 27.7145 56.653 27.043 57.4814 27.043H81.2839C82.1123 27.043 82.7839 27.7145 82.7839 28.543C82.7839 29.3714 82.1123 30.043 81.2839 30.043H57.4814C56.653 30.043 55.9814 29.3714 55.9814 28.543Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9812 35.4568C55.9812 34.6284 56.6528 33.9568 57.4812 33.9568H81.2837C82.1121 33.9568 82.7837 34.6284 82.7837 35.4568C82.7837 36.2852 82.1121 36.9568 81.2837 36.9568H57.4812C56.6528 36.9568 55.9812 36.2852 55.9812 35.4568Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5216 49.4523C50.1921 50.129 50.1871 51.2212 49.5103 51.8918L38.7449 62.5584C38.0703 63.2268 36.9825 63.2243 36.311 62.5528L29.6937 55.9356C29.0201 55.2619 29.0201 54.1697 29.6937 53.496C30.3674 52.8224 31.4596 52.8224 32.1332 53.496L37.5364 58.8992L47.0821 49.441C47.7588 48.7705 48.851 48.7755 49.5216 49.4523Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9814 52.543C55.9814 51.7145 56.653 51.043 57.4814 51.043H81.2839C82.1123 51.043 82.7839 51.7145 82.7839 52.543C82.7839 53.3714 82.1123 54.043 81.2839 54.043H57.4814C56.653 54.043 55.9814 53.3714 55.9814 52.543Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9812 59.4568C55.9812 58.6284 56.6528 57.9568 57.4812 57.9568H81.2837C82.1121 57.9568 82.7837 58.6284 82.7837 59.4568C82.7837 60.2852 82.1121 60.9568 81.2837 60.9568H57.4812C56.6528 60.9568 55.9812 60.2852 55.9812 59.4568Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5216 73.4523C50.1921 74.129 50.1871 75.2212 49.5103 75.8918L38.7449 86.5584C38.0703 87.2268 36.9825 87.2243 36.311 86.5528L29.6937 79.9355C29.0201 79.2619 29.0201 78.1697 29.6937 77.496C30.3674 76.8224 31.4596 76.8224 32.1332 77.496L37.5364 82.8992L47.0821 73.441C47.7588 72.7705 48.851 72.7755 49.5216 73.4523Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9814 76.543C55.9814 75.7145 56.653 75.043 57.4814 75.043H81.2839C82.1123 75.043 82.7839 75.7145 82.7839 76.543C82.7839 77.3714 82.1123 78.043 81.2839 78.043H57.4814C56.653 78.043 55.9814 77.3714 55.9814 76.543Z"
        fill={color ?? 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9812 83.4568C55.9812 82.6284 56.6528 81.9568 57.4812 81.9568H81.2837C82.1121 81.9568 82.7837 82.6284 82.7837 83.4568C82.7837 84.2852 82.1121 84.9568 81.2837 84.9568H57.4812C56.6528 84.9568 55.9812 84.2852 55.9812 83.4568Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};
