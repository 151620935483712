import { type ElementRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ClickedOutside from '../../../../../hooks/ClickedOutside';

import { type DropdownV2Styled } from '../../styles';

type DropdownCoreProps = {
  error?: boolean;
  errorMessage?: string;
};

export const useDropdownCore = (prop: DropdownCoreProps) => {
  const dropdownWrapperRef = useRef<ElementRef<typeof DropdownV2Styled>>(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(prop.error);
  const [errorMessage, setErrorMessage] = useState(prop.errorMessage);
  const [openChildren, setOpenChildren] = useState<string[]>([]);
  const openChildrenSet = useMemo(() => new Set(openChildren), [openChildren]);

  useEffect(() => {
    setError(prop.error);
  }, [prop.error]);

  useEffect(() => {
    setErrorMessage(prop.errorMessage);
  }, [prop.errorMessage]);

  ClickedOutside(dropdownWrapperRef, () => {
    setOpen(false);
  });

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      const elementFullyChecked = dropdownWrapperRef.current?.querySelector(
        '[data-is-selected="true"]',
      );
      const elementPartialChecked = dropdownWrapperRef.current?.querySelector(
        '[data-is-selected="partial"]',
      );

      if (elementFullyChecked) {
        elementFullyChecked.scrollIntoView({
          behavior: 'instant',
          block: 'center',
          inline: 'center',
        });
      } else if (elementPartialChecked) {
        elementPartialChecked.scrollIntoView({
          behavior: 'instant',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [open]);

  const toggleOpenChild = (value: string) => {
    if (openChildrenSet.has(value)) {
      setOpenChildren(openChildren.filter((item) => item !== value));
    } else {
      setOpenChildren([...openChildren, value]);
    }
  };

  const closeAllChildren = () => {
    setOpenChildren([]);
  };

  const isChildOpen = useCallback(
    (value: string) => {
      return openChildrenSet.has(value);
    },
    [openChildrenSet],
  );

  return {
    open,
    error,
    setError,
    toggleOpen,
    errorMessage,
    setErrorMessage,
    toggleOpenChild,
    setOpenChildren,
    closeAllChildren,
    isChildOpen,
    dropdownRef: dropdownWrapperRef,
  };
};
