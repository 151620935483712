import { useCallback, useEffect, useState } from 'react';
import { useAuth0, useTransactionId } from 'core';
import { apiFetch } from '../../../../../apps/followup/src/hooks/useApi/useApi';
import { type $DefaultObj, type $EntityAPIItem, type $PersistEntityFn } from './types';

const useSyncPersistedEntities = (): [$DefaultObj, $PersistEntityFn, boolean] => {
  const [persistedEntities, setPersistedEntities] = useState<$DefaultObj>({});
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const transactionId = useTransactionId();

  useEffect(() => {
    const fetchEntities = async () => {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) return;

      const data = await apiFetch('/user-entities', accessToken, transactionId, {
        method: 'GET',
      })
        .then((res) => {
          return res.json() as unknown as $DefaultObj;
        })
        .finally(() => {
          setLoading(false);
        });

      setPersistedEntities(data);
    };

    void (async () => {
      const accessToken = await getAccessTokenSilently();

      if (accessToken && !subscribed) {
        setSubscribed(true);
        fetchEntities().catch((e: unknown) => {
          console.error(e);
        });
      }
    })();
  }, [subscribed, setSubscribed, getAccessTokenSilently, transactionId]);

  const persistEntity = useCallback(
    async (item: $EntityAPIItem) => {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) return;

      const response = await apiFetch('/user-entities', accessToken, transactionId, {
        method: 'POST',
        body: JSON.stringify(item),
      }).then((res) => res.json());

      setPersistedEntities({
        ...persistedEntities,
        [response.entityKey]: [
          {
            id: response.id,
            value: response.entityValue,
          },
        ],
      } as unknown as $DefaultObj); // Todo: fix ugly ass typecasting

      return response;
    },
    [getAccessTokenSilently, persistedEntities, transactionId],
  );

  return [persistedEntities, persistEntity, loading];
};

export default useSyncPersistedEntities;
