import { type PictogramIconProps } from './types';

// arrows_05
export const ArrowsTriangle = ({ color }: PictogramIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6791_15680)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.3158 14.005C40.752 7.52728 47.608 3.12305 55.6399 3.12305C63.6643 3.12305 70.518 7.50973 73.9477 13.973C73.9531 13.9826 73.971 14.0142 74.0081 14.0788C74.0496 14.1511 74.1073 14.2513 74.1806 14.3781C74.327 14.6316 74.5335 14.9882 74.7915 15.4335C75.3076 16.3237 76.0288 17.5663 76.8863 19.0424C78.6011 21.9947 80.8598 25.8803 83.1093 29.7491C85.3588 33.6179 87.5991 37.4697 89.277 40.3543L92.0702 45.156C92.0703 45.1562 92.0704 45.1563 90.7739 45.9106C89.4773 46.6649 89.4773 46.6648 89.4771 46.6646L86.6838 41.8627C85.0059 38.978 82.7655 35.1261 80.5159 31.2571C76.0295 23.5414 71.4735 15.702 71.3122 15.4064L71.3035 15.3903C68.3896 9.88924 62.5448 6.12305 55.6399 6.12305C48.7371 6.12305 42.8908 9.89732 39.966 15.4108C39.7936 15.7356 37.8543 19.1099 35.9912 22.3469C35.0483 23.985 34.1145 25.606 33.4163 26.8175L32.256 28.8306C32.256 28.8307 32.2557 28.8312 30.9562 28.0819C29.6568 27.3326 29.6568 27.3326 29.6569 27.3325L30.817 25.3197C31.515 24.1084 32.4486 22.4879 33.3911 20.8503C34.3337 19.2127 35.2849 17.5587 36.0182 16.2804C36.3849 15.6411 36.6967 15.0966 36.9254 14.6955C37.0399 14.4948 37.1328 14.3312 37.2012 14.21C37.2355 14.1494 37.2628 14.1007 37.2832 14.0641C37.3052 14.0246 37.3136 14.009 37.314 14.0083L37.3158 14.005ZM30.9562 28.0819L29.6569 27.3325C29.2431 28.0502 29.4893 28.9676 30.207 29.3814C30.9247 29.7952 31.8422 29.5483 32.256 28.8306L30.9562 28.0819ZM90.7739 45.9106L89.4771 46.6646C89.8937 47.3806 90.8121 47.6238 91.5282 47.2072C92.2442 46.7906 92.4868 45.872 92.0702 45.156L90.7739 45.9106Z"
          fill={color ?? 'currentColor'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.3592 33.9421C96.1594 34.1565 96.6343 34.979 96.4199 35.7792L93.2098 47.7605C93.1068 48.1448 92.8553 48.4725 92.5108 48.6714C92.1662 48.8703 91.7567 48.9242 91.3724 48.8211L79.4009 45.611C78.6008 45.3964 78.1261 44.5738 78.3406 43.7736C78.5552 42.9735 79.3778 42.4988 80.1779 42.7133L90.7003 45.5349L93.5221 35.0028C93.7365 34.2026 94.559 33.7277 95.3592 33.9421Z"
          fill={color ?? 'currentColor'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.6223 55.5466C96.3393 55.1318 97.2569 55.3767 97.6718 56.0938L96.6221 56.7011C97.6718 56.0938 97.6718 56.0937 97.6718 56.0938L98.8358 58.1049C99.5363 59.315 100.474 60.9337 101.421 62.5688C102.369 64.204 103.326 65.855 104.067 67.1298C104.437 67.7673 104.753 68.3099 104.986 68.7091C105.103 68.9089 105.198 69.0717 105.269 69.192C105.305 69.2523 105.333 69.3008 105.355 69.3374L105.381 69.381C105.386 69.3895 105.389 69.3941 105.39 69.3958C109.284 75.6072 109.668 83.7469 105.654 90.7038C101.64 97.6582 94.4226 101.397 87.1011 101.133C87.0996 101.133 87.0837 101.133 87.0463 101.133C87.0297 101.133 87.009 101.133 86.9834 101.132C86.935 101.132 86.8756 101.132 86.8053 101.132C86.7553 101.132 86.6997 101.132 86.6388 101.132C86.3462 101.132 85.9343 101.132 85.4196 101.133C84.3905 101.134 82.9538 101.137 81.2466 101.141C77.8324 101.149 73.3383 101.162 68.8632 101.176C64.3883 101.189 59.9328 101.204 56.596 101.214L51.0415 101.233C51.0413 101.233 51.0411 101.233 51.0361 99.7327C51.0311 98.2327 51.0313 98.2327 51.0315 98.2327L56.5862 98.2145C59.9232 98.2037 64.3789 98.1895 68.8541 98.1759C77.7719 98.1489 86.8497 98.1237 87.1956 98.1347L87.2026 98.1349C93.4302 98.3619 99.6027 95.1869 103.055 89.2043C106.507 83.2231 106.161 76.2718 102.847 70.9863L102.844 70.9822C102.664 70.6936 100.707 67.3203 98.8253 64.0729C97.8776 62.4373 96.94 60.8182 96.2394 59.6079L95.0755 57.5968C95.0754 57.5967 95.0751 57.5962 96.1308 56.9854L95.0755 57.5968C94.6606 56.8797 94.9052 55.9615 95.6223 55.5466ZM51.0361 99.7327L51.0315 98.2327C50.2031 98.2355 49.5334 98.9093 49.5361 99.7377C49.5389 100.566 50.2131 101.235 51.0415 101.233L51.0361 99.7327Z"
          fill={color ?? 'currentColor'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.6852 90.1972C59.271 90.7829 59.271 91.7327 58.6852 92.3185L50.984 100.02L58.6858 107.73C59.2713 108.316 59.2707 109.266 58.6846 109.851C58.0985 110.437 57.1487 110.436 56.5633 109.85L47.802 101.079C47.2168 100.493 47.2171 99.544 47.8026 98.9584L56.5639 90.1972C57.1497 89.6114 58.0994 89.6114 58.6852 90.1972Z"
          fill={color ?? 'currentColor'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9805 100.626C17.6525 100.889 10.4103 97.154 6.39441 90.1981C2.3822 83.2488 2.75433 75.12 6.63686 68.9182C6.64239 68.9088 6.6608 68.8774 6.69827 68.8129C6.74011 68.7409 6.79802 68.6408 6.87125 68.5139C7.01759 68.2604 7.22318 67.9033 7.47972 67.4571C7.9927 66.5651 8.70813 65.3192 9.55783 63.8386C11.2572 60.8774 13.4928 56.9785 15.7186 53.0959C17.9442 49.2134 20.1599 45.3473 21.8191 42.4519L24.5809 37.6321C24.581 37.6319 24.5811 37.6318 25.8826 38.3774C27.1841 39.1231 27.184 39.1233 27.1839 39.1235L24.422 43.9435C22.7627 46.839 20.547 50.7052 18.3212 54.5879C13.8824 62.3311 9.37134 70.1964 9.19596 70.4839L9.18632 70.4994C5.87925 75.7735 5.54005 82.7184 8.99248 88.6981C12.4439 94.6761 18.6357 97.8521 24.8729 97.6283C25.2403 97.6152 29.1322 97.6075 32.8671 97.6025C34.7572 97.6 36.628 97.5982 38.0262 97.5971L40.3497 97.5954C40.3498 97.5954 40.3504 97.5954 40.3512 99.0954C40.3521 100.595 40.352 100.595 40.3519 100.595L38.0287 100.597C36.6307 100.598 34.7605 100.6 32.8711 100.603C30.9816 100.605 29.0736 100.608 27.5998 100.612C26.8628 100.614 26.2355 100.617 25.7737 100.619C25.5426 100.62 25.3545 100.622 25.2153 100.623C25.1457 100.624 25.0899 100.624 25.048 100.625C25.0028 100.626 24.9851 100.626 24.9843 100.626L24.9805 100.626ZM40.3512 99.0954L40.3519 100.595C41.1803 100.595 41.8517 99.923 41.8512 99.0945C41.8508 98.2661 41.1781 97.5949 40.3497 97.5954L40.3512 99.0954ZM25.8826 38.3774L27.1839 39.1235C27.5957 38.4047 27.3471 37.4878 26.6283 37.0759C25.9095 36.6641 24.9927 36.9133 24.5809 37.6321L25.8826 38.3774Z"
          fill={color ?? 'currentColor'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2468 40.4274C13.0324 39.6272 13.5072 38.8047 14.3074 38.5903L26.2886 35.3797C26.6729 35.2767 27.0824 35.3306 27.427 35.5296C27.7715 35.7286 28.0229 36.0563 28.1258 36.4406L31.3315 48.4133C31.5457 49.2135 31.0707 50.0359 30.2705 50.2502C29.4702 50.4644 28.6478 49.9894 28.4335 49.1892L25.616 38.6658L15.0839 41.488C14.2837 41.7024 13.4612 41.2276 13.2468 40.4274Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_6791_15680">
          <rect width="100%" height="100%" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
