import { useEffect, useState } from 'react';
import { useFlag } from '../..';
import { useCountries } from '../../hooks/useCountries';
import {
  getUsersServices,
  userIsUserAdminForAnyPartner as _userIsAdminForAnyPartner,
} from '../../utils/services';
import { getServiceUrl } from '../../utils/environment';
import { MenuLinksWrapper, StyledLink } from './styles';
import type { TextType } from '..';
import type { Profile } from '../../Profile';
import type { MenuService } from '../../Services/useServices';

type MenuLinksProps = {
  profile: Profile | null;
  allServices?: MenuService[];
  activeLink?: string;
  text: TextType;
  serviceNames?: Record<string, string>;
};

const MenuLinks = ({
  profile,
  allServices = [],
  activeLink,
  text,
  serviceNames,
}: MenuLinksProps) => {
  const [userServices, setUserServices] = useState<MenuService[]>();
  const [userIsAdminForAnyPartner, setUserIsAdminForAnyPartner] = useState(false);
  const {
    supportedCountries: { sweden, denmark },
  } = useCountries();
  const includeDenmark = useFlag('co2-impact-denmark-checkout');

  useEffect(() => {
    if (allServices.length && profile) {
      setUserServices(getUsersServices(profile, allServices));
      setUserIsAdminForAnyPartner(_userIsAdminForAnyPartner(profile));
    }
  }, [profile, allServices]);

  const hasFollowUpService = userServices?.find(
    (service) => service.name === 'services.follow.up.name',
  );

  return (
    <MenuLinksWrapper>
      <HomeLink activeLink={activeLink} text={text} />
      {userServices?.map((userService) => (
        <ServiceLink
          key={userService.id}
          activeLink={activeLink}
          userService={userService}
          serviceNames={serviceNames}
        />
      ))}

      {(sweden === true || (denmark && includeDenmark)) && hasFollowUpService && (
        <CO2Link text={text} activeLink={activeLink} />
      )}

      {userIsAdminForAnyPartner && <UserAdminLink text={text} activeLink={activeLink} />}
    </MenuLinksWrapper>
  );
};

const HomeLink = ({ activeLink, text }: { activeLink?: string; text: TextType }) => {
  return (
    <StyledLink
      to="/"
      isActive={'touchpointx' === activeLink && !location.pathname.startsWith('/support')}
      data-testid="home"
    >
      {text.homeLink}
    </StyledLink>
  );
};

const ServiceLink = ({
  activeLink,
  userService,
  serviceNames,
}: {
  activeLink?: string;
  userService: MenuService;
  serviceNames?: Record<string, string>;
}) => {
  return (
    <StyledLink
      key={userService.id}
      to={getServiceUrl(userService)}
      isActive={userService.name === activeLink}
      data-testid={userService.linkText}
    >
      {serviceNames?.[userService.name]}
    </StyledLink>
  );
};

const CO2Link = ({ text, activeLink }: { text: TextType; activeLink?: string }) => {
  return (
    <StyledLink to="/co2impact" data-testid="co2_impact" isActive={'co2_impact' === activeLink}>
      {text.co2Impact}
    </StyledLink>
  );
};

const UserAdminLink = ({ text, activeLink }: { text: TextType; activeLink?: string }) => {
  return (
    <StyledLink to="/admin" data-testid="admin" isActive={'usermanagement' === activeLink}>
      {text.userManagement}
    </StyledLink>
  );
};

export default MenuLinks;
