export const routePathsAdmin: Record<string, string> = {
  HOME: '/admin',
  PROFILE: '/admin/profile',
  PROFILE_SUBS: '/admin/profile/*',
  PROFILE_DETAILS: '/admin/profile/details',
  PROFILE_PASSWORD: '/admin/profile/password',
  PROFILE_LOCATIONS: '/admin/profile/locations',
  PROFILE_SUBSCRIPTIONS: '/admin/profile/subscriptions',
  USERS: '/admin/users',
  USER_DETAILS: '/admin/users/:userId',
  ADD_LOCATION: '/admin/users/:userId/add-location',
  LOCATIONS: '/admin/locations',
  LOCATION_DETAILS: '/admin/locations/:locationId',
  REQUEST_NEW_LOCATION: '/admin/locations/request-new-location',
  REQUEST_NEW_LOCATION_CONFIRMATION:
    '/admin/locations/request-new-location/confirmation/:transactionId',
  INVITE: '/admin/users/invite',
  SUBSCRIPTIONS: '/admin/subscriptions',
} as const;
