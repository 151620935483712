import { useEffect, type RefObject, type MouseEvent } from 'react';

function ClickedOutside(ref: RefObject<HTMLDivElement>, onClicked: (close: boolean) => void) {
  const clickedOutsideOfRef = (event: TouchEvent | MouseEvent | Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) onClicked(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickedOutsideOfRef);

    document.addEventListener('touchstart', clickedOutsideOfRef);

    return () => {
      document.removeEventListener('mousedown', clickedOutsideOfRef);
      document.removeEventListener('touchstart', clickedOutsideOfRef);
    };
  });
}
export default ClickedOutside;
