import { WithCustomerPortalApi } from '../../customerPortalApi';
import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { getApiUrl } from '../../utils/environment';

const apiUrl = getApiUrl();

export const useHasSeenRoleIndustry = WithCustomerPortalApi<
  undefined,
  UseMutationResult<undefined, Error, void>
>((fetchFn) => {
  const url = `${apiUrl}/usermanagement/user/role-industry/seen`;

  const query = useMutation({
    mutationFn: () =>
      fetchFn({
        url,
        options: {
          method: 'POST',
        },
      }),
  });

  return query;
});
