import { IconWrapper } from './style';
import type { IconProps } from '../../types';

export const More = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
      fill="none"
      viewBox="0 0 22 22"
    >
      <circle
        cx="12"
        cy="12"
        r="1"
        fill={color ?? 'currentColor'}
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-1, -1)"
      ></circle>
      <circle
        cx="12"
        cy="5"
        r="1"
        fill={color ?? 'currentColor'}
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-1, -1)"
      ></circle>
      <circle
        cx="12"
        cy="19"
        r="1"
        fill={color ?? 'currentColor'}
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-1, -1)"
      ></circle>
    </svg>
  </IconWrapper>
);
