import type { Profile } from '../Profile';
import type { MenuService } from '../Services';

export const userIsUserAdminForAnyPartner = (profile: Profile) => {
  if (!profile.permissions) {
    return false;
  }
  if (profile.permissions.superUser) {
    return true;
  }

  const permissions = profile.permissions;

  if (!permissions.businessPartners.length) {
    return false;
  }

  return permissions.businessPartners.some((p) => p.services.some((s) => s.userManagement));
};

export const getUsersServices = (profile: Profile | null, allServices: MenuService[]) => {
  if (!profile?.permissions?.businessPartners) {
    return [];
  }
  if (profile.permissions.superUser) {
    return allServices;
  }

  const permissions = profile.permissions;

  const customServiceNames = permissions.businessPartners.flatMap((p) => {
    if (!p.services) {
      return [];
    }

    return p.services.map((s) => s.name);
  });

  const customServices = allServices.filter((s) => customServiceNames.includes(s.name));

  return customServices;
};

export const userHasSwedishPartner = (profile: Profile) => {
  const hasSwedishPartner = !!profile.permissions?.businessPartners.some((bp) =>
    bp.id.startsWith('104_'),
  );

  return hasSwedishPartner;
};
