import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEventTracking } from './useEventTracking/useEventTracking';
type usePageTitleOptions = {
  replacePatterns?: { pattern: RegExp; replacement: string }[];
  pathIgnorePatterns?: RegExp[];
};
export const usePageTitle = (appPrefix: string, options?: usePageTitleOptions) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { trackPageView } = useEventTracking();

  useEffect(() => {
    const defaultTitle = 'Stena Recycling';

    if (!location.pathname) return;
    const titleKey = getTitleKeyFromRoute(location.pathname, appPrefix, options);

    const fullKey = `pagetitle.${titleKey}`;

    const i18nKeyExists = i18n.exists(fullKey);

    if (!i18nKeyExists && titleKey) {
      console.warn(`Translation key "${fullKey}" not found for path "${location.pathname}"`);
    }

    const title = !titleKey || !i18n.exists(fullKey) ? defaultTitle : t(fullKey);
    const englishTitle =
      !titleKey || !i18n.exists(fullKey) ? defaultTitle : t(fullKey, { lng: 'en' });

    trackPageView(englishTitle);
    document.title = title;
  }, [location.pathname]);
};
const getTitleKeyFromRoute = (path: string, appPrefix: string, options?: usePageTitleOptions) => {
  if (!path) return undefined;
  const appPrefixWithoutUnderscore = appPrefix.replace('_', '');

  path = path.replace(appPrefix, '').replace(appPrefixWithoutUnderscore, '');

  if (path === '/') return appPrefix + '.home';

  let key = appPrefix + path;

  if (options?.pathIgnorePatterns) {
    options.pathIgnorePatterns.forEach((pattern) => {
      key = key.replace(pattern, '');
    });
  }
  if (options?.replacePatterns) {
    options.replacePatterns.forEach((p) => {
      key = key.replace(p.pattern, p.replacement);
    });
  }

  key = key
    .replace(/([/])\1+/, '.')
    .replace(/\//g, '.')
    .replace(/\.$/, '')
    .toLowerCase();

  return key;
};
